import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Color, FontTextSize } from '@pafcloud/style';
import { DisclosureWidget } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { BonusColors } from '../bonus-colors';

export const FlowBanner = styled.div({
  // Make the image span the full width of the flow and align with the flow header on top
  width: 'calc(100% + var(--side-margin) * 2)',
  margin: 'calc(var(--top-margin) * -1) calc(var(--side-margin) * -1) 0',
});

export const BannerPicture = styled.picture({
  position: 'relative',
  display: 'flex',
  aspectRatio: '16/9',
  width: '100%',

  // iOS 14 does not support aspect-ratio
  '@supports not (aspect-ratio: 16/9)': {
    '::before': {
      content: '""',
      float: 'left',
      height: 0,
      paddingTop: '56.25%',
    },
    '::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
});

export const BannerImg = styled.img({
  display: 'flex',
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  objectPosition: '50% 50%',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const Percentage = styled.th({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gridColumn: 1,
  fontSize: FontTextSize.Normal,
  fontWeight: 'bold',
  color: BonusColors.TurnoverPercentage,
  span: {
    marginLeft: 5,
  },
});

export const Divider = styled.hr({
  border: 'none',
  borderTop: `1px solid ${BonusColors.Divider}`,
  margin: '40px 0',
});

export const TermsText = styled.div({
  'ol, ul': {
    li: {
      marginBottom: 8,
    },
    a: {
      color: BonusColors.Link,
    },
  },
});

export const StepListCriteria = styled.ol({
  padding: 0,
});

export const StepListItem = styled.li<{ shouldStrikeThrough?: boolean }>(
  {
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    lineHeight: 1.5,
    marginBottom: 4,
  },
  ({ shouldStrikeThrough }) =>
    shouldStrikeThrough
      ? {
          textDecoration: 'line-through',
          color: BonusColors.DisabledRow,
        }
      : undefined,
);

export const StepListCriteriaItem = styled(StepListItem)({
  marginLeft: 24,
});

export const StepListItemMarker = styled.span({
  justifySelf: 'center',
  alignSelf: 'center',
  lineHeight: 0,
});

export const Bullet = styled.i({
  '--bullet-size': '0.5rem',

  display: 'inline-block',
  width: 'var(--bullet-size)',
  height: 'var(--bullet-size)',
  opacity: 0.5,
  backgroundColor: BonusColors.Bullet,
  borderRadius: '50%',
  alignSelf: 'center',
});

export const RequirementsListItemDone = styled.strong({
  color: BonusColors.DisabledRow,
});

const floatingCtaStyles = css({
  position: 'sticky',
  bottom: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  // button min-height is 3.5rem so this moves it down one button-height-unit
  transform: 'translateY(3.5rem)',
  pointerEvents: 'none',

  'button, a': {
    pointerEvents: 'all',
  },

  '> button, > a': {
    boxShadow: $buildEnv.theme === 'momentum-neon' ? undefined : `0 0 16px 8px rgba(0,0,0,0.15)`,

    ':not(:active, :focus, :hover)': {
      '&[data-button-action="close"], &[data-link-target="/profile/bonus"]': {
        background: Color.Surface.Floating.Background,
        boxShadow: $buildEnv.theme === 'momentum-neon' ? `0 0 4px 2px #15564a` : undefined,
      },
    },
    ':active, :focus, :hover': $buildEnv.theme === 'momentum-neon' && {
      '&[data-button-action="close"], &[data-link-target="/profile/bonus"]': {
        background: Color.Surface.Base.Background,
      },
    },
  },
});

export const FloatingCta = styled.div(floatingCtaStyles, () => {
  if ($buildEnv.theme === 'momentum-neon') {
    return {
      ':not(:active, :focus, :hover)': {
        '&[data-button-action="close"], &[data-link-target="/profile/bonus"]': {
          background: Color.Surface.Floating.Background,
          boxShadow: $buildEnv.theme === 'momentum-neon' ? `0 0 4px 2px #15564a` : undefined,
        },
      },
      ':active, :focus, :hover': {
        '&[data-button-action="close"], &[data-link-target="/profile/bonus"]': {
          background: Color.Surface.Base.Background,
        },
      },
    };
  }
});

export const DisclosureWidgetFullWidth = styled(DisclosureWidget)<{ withDivider?: boolean }>(({ withDivider }) => ({
  width: '100%',
  marginTop: withDivider ? 0 : 64,
}));

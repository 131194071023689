import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { Breakpoint, Color } from '@pafcloud/style';
import { Disclosure } from './Disclosure';

/**
 * Available custom properties
 * --disclosure-border
 * --disclosure-background
 *
 * --disclosure-summary-background
 * --disclosure-summary-border
 * --disclosure-summary-text
 *
 * --disclosure-content-background
 * --disclosure-content-text
 */
type DetailsProps = {
  fullWidth: boolean | undefined;
  margin: string | undefined;
};

const Details = styled(Disclosure)<DetailsProps>(({ fullWidth, margin }) => ({
  margin: margin ? margin : 'auto',
  border: `var(--disclosure-border, 1px solid ${Color.TextColors.BodyText}45)`,
  borderRadius: Color.CornerRadius.Base,
  background: 'var(--disclosure-background)',
  width: fullWidth ? '100%' : 'auto',
}));

const Summary = styled(Disclosure.Summary)({
  padding: 16,
  borderRadius: Color.CornerRadius.Base,
  background: 'var(--disclosure-summary-background)',
  color: `var(--disclosure-summary-text, ${Color.TextColors.BodyText})`,
  fontWeight: 'bold',

  '[open] &': {
    borderBottom: `var(--disclosure-summary-border, 1px solid ${Color.TextColors.BodyText}45)`,
    borderRadius: 0,
  },

  [Breakpoint.LaptopOrLarger]: {
    padding: 16,
  },
});

// ios doesn't allow flex or grid on summary element
const SummaryContent = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const Content = styled.div({
  background: 'var(--disclosure-content-background)',
  color: `var(--disclosure-content-text, ${Color.TextColors.BodyText})`,
  padding: 16,
});

type DisclosureWidgetProps = {
  children: ReactNode;
  title: string;
  className?: string;
  fullWidth?: boolean;
  margin?: string;
};

export const DisclosureWidget: FC<DisclosureWidgetProps> = ({ children, className, title, fullWidth, margin }) => {
  return (
    <Details className={className} fullWidth={fullWidth} margin={margin}>
      <Summary>
        <SummaryContent>
          <span>{title}</span>
          <Disclosure.SummaryIcon />
        </SummaryContent>
      </Summary>
      <Content>{children}</Content>
    </Details>
  );
};
